import * as THREE from 'three';

let camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 10);
camera.position.z = 10;

const scene = new THREE.Scene();
const group = new THREE.Object3D();
scene.add(group);

const loader = new THREE.TextureLoader();
loader.load('/images/cat.jpg', texture => {
  const geometry = new THREE.SphereGeometry(4, 60, 60);

  const material = new THREE.MeshBasicMaterial({ map: texture });
  const mesh = new THREE.Mesh(geometry, material);
  group.add(mesh);
});

const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setClearColor(0xFFFFFF);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setAnimationLoop(render);
document.body.appendChild(renderer.domElement);

camera.lookAt(scene.position);

function render(time: number) {
  group.rotation.y = time / 1500;

  renderer.render(scene, camera);
}

function debounce(f: Function, delay: number) {
  let timer = 0;
  return function(...args: any[]) {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => f.apply(this, args), delay);
  }
}

const resizeObserver = new ResizeObserver(debounce(() => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}, 50));

resizeObserver.observe(document.body);
